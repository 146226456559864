<template>
  <div class="learning">
    <TopBar :type="2" :pageTitle="'领取证书'" />
    <ul class="class-list">
      <p v-if="showText==1">您已领取过证书</p>
       <p v-if="showText==2">您还未领取证书</p>
    </ul>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'learning',
  data(){
    return {
      showText:''
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getInfo(); 
  },
  methods: {
    getInfo(){
        this.$axios.post(`/v1/personal/lmgxxyIsGetCert`,{},{useLog:true}).then(res=>{
            console.log(res)
            if(res.code==0){
                // 已领取过证书
                this.showText = 1
            }
            if(res.code==1){
                // 未领取过证书
                this.showText = 2
            }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.class-list{
 p{
  text-align: center;
  line-height: 40vh;
  font-size: 40px;
  font-weight: bold;
  color: #999;
 }
}

</style>
